@font-face {
    font-family: "GothamBold";
    src: url("estilos/fonts/Gotham-Bold.otf");
}

@font-face {
    font-family: "GothamMedium";
    src: url("estilos/fonts/GothamMedium.ttf");
}

@font-face {
    font-family: "GothamLight";
    src: url("estilos/fonts/Gotham-Light.otf");
}

@font-face {
    font-family: "FjallaOne";
    src: url("estilos/fonts/FjallaOne-Regular.ttf");
}

@font-face {
    font-family: "AllertaRegular";
    src: url("estilos/fonts/Allerta-Regular.ttf");
}